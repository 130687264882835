$rp-select-selection-min-height:      subtract(2rem, $border-width * 2);

@import "ChipList";
@import "InlineSearch";
@import "ActionList";

.rp-select-selection {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  min-height: $rp-select-selection-min-height;
  padding: 0 $input-padding-x;

  user-select: none;

  .rp-select-selection-text {
    display: flex;
    align-items: center;
    flex: 1 1 auto;

    height: $rp-select-selection-min-height;

    @include font-settings($label-medium);

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.placeholder {
      color: $palette-black-500;
    }
  }
}
