body {
  background: $neutral-bg;
}

main {
  display: flex;
  flex-direction: column;

  min-height: 100vh;
}

body.has-header {
  main {
    padding-top: $header-height;
  }
}

@include media-breakpoint-up(md) {
  body.has-sidebar {
    main {
      padding-left: $sidebar-width;
    }
  }
}

.main-table-container,
.detail-page-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.main-table-container {
  padding: $content-padding-x $content-padding-x $content-padding-y;
}

.detail-page-container {
  padding: $content-padding-y $content-padding-x;
}
