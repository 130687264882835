@import "~flatpickr/dist/flatpickr";

.flatpickr-calendar {
  border-radius: $border-radius;

  &.arrowTop:before {
    border-bottom-color: $border-color;
  }

  &.arrowBottom:before {
    border-top-color: $border-color;
  }

  &.hasTime .flatpickr-time {
    border-top-color: $border-color;
  }

  .flatpickr-months {
    .flatpickr-month {
      color: $body-color;
      fill: $body-color;
    }

    .flatpickr-prev-month,
    .flatpickr-next-month {
      color: $body-color;
      fill: $body-color;

      svg {
        display: block;
      }

      &:hover {
        color: $primary;

        svg {
          fill: $primary;
        }
      }
    }
  }
}

.flatpickr-day {
  color: $body-color;
  border-radius: $border-radius;

  &,
  &.prevMonthDay,
  &.nextMonthDay {
    &.inRange,
    &.today.inRange,
    &:hover,
    &:focus {
      background: $border-color;
      border-color: $border-color;
    }
  }

  &.today {
    border-color: $primary-bg;

    &:hover,
    &:focus {
      color: $body-color;
      background: $primary-bg;
      border-color: $primary-bg;
    }
  }

  &.selected,
  &.startRange,
  &.endRange {
    &,
    &.inRange,
    &:focus,
    &:hover,
    &.prevMonthDay,
    &.nextMonthDay {
      color: $white;
      background: $primary;
      border-color: $primary;
    }
  }

  &.flatpickr-disabled,
  &.flatpickr-disabled:hover,
  &.prevMonthDay,
  &.nextMonthDay,
  &.notAllowed,
  &.notAllowed.prevMonthDay,
  &.notAllowed.nextMonthDay {
    color: $disabled;
  }

  &.flatpickr-disabled,
  &.flatpickr-disabled:hover {
    color: $disabled;
  }
}
