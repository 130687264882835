.column-select {
  .cs-groups {
    display: flex;
    flex-wrap: wrap;

    .cs-group {
      width: 20rem;
      margin: .25rem 1.5rem 1.5rem .25rem;
    }
  }

  .cs-group-all {
    font-weight: $font-weight-bold;
  }

  .cs-ordering {
    .badge {
      margin: .25rem;

      cursor: pointer;
      user-select: none;
    }
  }
}
