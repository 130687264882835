.sso-content {
  $white:        #ffffff;
  $black:        #2f2f2f;
  $border-black: #8c8c8c;

  #sso-microsoft-btn-black {
    @include button-style($white, $black, $black, $black, $white, $border-black, $black, $white, $border-black);
  }

  #sso-microsoft-btn-white {
    @include button-style($black, $white, $border-black, $white, $black, $black, $white, $black, $black);
  }
}