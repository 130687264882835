.rp-filter {
  overflow: hidden;

  background: $white;
  border-bottom: $border-width solid $border-color;

  .rp-tabs & {
    margin-bottom: $spacer;

    border: $border-width solid $border-color;
    border-radius: $card-border-radius;
  }

  .rp-filter-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: $content-padding-y $content-padding-x;

    background: $neutral-surface-var-alt;
    border-bottom: $border-width solid $border-color-alt;

    h4 {
      margin: 0;

      @include font-settings($title-medium);
      line-height: 2rem;
    }

    .rp-filter-header-actions {
      display: flex;
      align-items: center;
    }
  }

  .rp-filter-container:not(.show) + .rp-filter-collapse {
    .rp-filter-hide {
      display: none;
    }
  }
  .rp-filter-container.show + .rp-filter-collapse {
    .rp-filter-show {
      display: none;
    }
  }

  .rp-filter-additional:not(.show) + .rp-filter-buttons {
    .rp-filter-hide-additional {
      display: none;
    }
  }
  .rp-filter-additional.show + .rp-filter-buttons {
    .rp-filter-show-additional {
      display: none;
    }
  }

  .rp-filter-container {
    .container-fluid {
      padding: $content-padding-y $content-padding-x;
    }
  }

  .rp-filter-buttons {
    .btn {
      margin-right: $spacer * .5;
    }
  }

  .rp-filter-collapse {
    .container-fluid {
      padding: 0 $content-padding-x;
    }

    .col {
      display: flex;
      justify-content: flex-end;
    }
  }

  .rp-filter-show {
    @include caret(down);
  }

  .rp-filter-hide {
    @include caret(up);
  }
}
