.dropdown-menu-scroll-container {
  overflow: auto;
  max-height: subtract(100vh, $header-height);
}

.dropdown-item {
  &.btn {
    display: flex;
    border-radius: 0;

    font-weight: $btn-font-weight;

    &:not(a.disabled) {
      pointer-events: auto;
    }
  }
}

.dropdown-header {
  font-size: inherit;
}

.btn-dropdown-menu {
  padding: $btn-border-radius 0;

  border: $border-width solid $palette-blue-300;
  border-radius: $btn-border-radius;

  box-shadow: $elevation-600;

  &::before,
  .dropdown-divider {
    border-color: $palette-blue-300 !important;
  }

  .dropdown-divider:first-child,
  .dropdown-divider:last-child,
  .dropdown-divider + .dropdown-divider {
    display: none;
  }

  .dropdown-item {
    &:first-child {
      border-top-left-radius: $btn-border-radius;
      border-top-right-radius: $btn-border-radius;
    }

    &:last-child {
      border-bottom-right-radius: $btn-border-radius;
      border-bottom-left-radius: $btn-border-radius;
    }
  }
}

.dropdown-toggle-split {
  .btn-icon {
    display: none;
  }
}

.dropdown {
  .btn-dropdown-menu {
    margin-top: .25rem;
  }
}

.dropup {
  .btn-dropdown-menu {
    margin-bottom: .25rem;
  }
}

@mixin dropdown-menu-tip($vertical, $horizontal: middle) {
  &::before {
    content: "";

    position: absolute;
    z-index: -1;

    width: $dropdown-menu-tip-size;
    height: $dropdown-menu-tip-size;

    background-color: $dropdown-bg;
    border: $dropdown-border-width solid $dropdown-border-color;
    border-left: none;
    border-bottom: none;

    #{$vertical}: subtract(-$dropdown-menu-tip-size * .5, $border-width);

    @if $horizontal == left {
      left: $dropdown-menu-tip-size * 2 + $dropdown-menu-tip-size * .5;
    } @else if $horizontal == right {
      right: $dropdown-menu-tip-size * 2 + $dropdown-menu-tip-size * .5;
    } @else {
      left: subtract(50%, $dropdown-menu-tip-size * .5);
    }

    @if $vertical == top {
      transform: rotate(-45deg);
    } @else {
      transform: rotate(135deg);
    }
  }
}

.dropdown-menu-tip-n {
  @include dropdown-menu-tip(top);
}
.dropdown-menu-tip-ne {
  @include dropdown-menu-tip(top, right);
}
.dropdown-menu-tip-nw {
  @include dropdown-menu-tip(top, left);
}
.dropdown-menu-tip-s {
  @include dropdown-menu-tip(bottom);
}
.dropdown-menu-tip-se {
  @include dropdown-menu-tip(bottom, right);
}
.dropdown-menu-tip-sw {
  @include dropdown-menu-tip(bottom, left);
}
