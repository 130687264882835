$rp-fit-content-sidebar-width:      22.25rem;



.rp-fit-content-container {
  display: flex;
  overflow: auto;

  flex: 1 1 0;
  gap: $spacer;

  min-height: 0;

  .rp-fit-content-sidebar {
    display: flex;
    overflow: auto;

    width: $rp-fit-content-sidebar-width;
  }

  .rp-fit-content {
    display: flex;
    flex-direction: column;
    overflow: auto;

    flex: 1 1 0;
    gap: $spacer;
    min-width: 0;
  }

  .rp-table,
  .rp-interactive-list {
    flex: 1 1 0;
    min-height: 0;
    margin-bottom: 0;
  }
}
