footer {
  display: flex;
  align-items: center;

  height: $footer-height;
  margin-top: auto;
  padding: $footer-padding-top $content-padding-x $content-padding-y $content-padding-x;

  .language-menu {
    & > .dropdown-toggle {
      padding: $btn-padding-y-sm * .5 $btn-padding-x-sm * .5;
    }
  }
}
