.actionbar {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;

  .actionbar-left,
  .actionbar-right,
  .actionbar-menu {
    display: none;
  }

  .actionbar-left {
    flex-grow: 1;
  }

  @include media-breakpoint-up(xl) {
    .actionbar-left,
    .actionbar-right,
    .actionbar-menu {
      display: flex;
    }

    .actionbar-menu-collapsed {
      display: none;
    }
  }
}
