.rp-tabs {
  display: flex;
  flex-direction: column;
  flex: 1;

  .tab-content {
    display: flex;
    flex-direction: column;
    flex: 1;

    .tab-pane {
      padding-top: $spacer;

      &.active {
        display: flex;
        flex-direction: column;
        flex: 1;
      }
    }
  }
}
