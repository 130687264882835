.rp-scrollbar {
  &::-webkit-scrollbar {
    width: $scrollbar-size;
    height: $scrollbar-size;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollbar-color;
    border-radius: divide($scrollbar-size, 2);
  }

  &::-webkit-scrollbar-track {
    background: $scrollbar-bg;
    border-radius: divide($scrollbar-size, 2);
  }

  &.rp-scrollbar-offcanvas {
    &::-webkit-scrollbar {
      width: $border-width * 3;
      height: $border-width * 3;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: $border-width;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: $border-width;
    }
  }
}
