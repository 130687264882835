.list-group-container {
  display: flex;
  flex-wrap: wrap;

  gap: 1rem;
}

.list-group {
  .list-group-item {
    margin: 0;

    @include font-settings($label-medium);

    &:not(a.disabled) {
      pointer-events: auto;
    }
  }

  h4.list-group-item {
    @include font-settings($title-medium);
  }
}
