// ############################################################################
// Constants
// ############################################################################

$rp-rating-slider-size:             .125rem;
$rp-rating-slider-handle-size:      1.125rem;
$rp-rating-slider-offset:           divide(subtract($rp-rating-slider-handle-size, $rp-rating-slider-size), 2);
$rp-rating-dot-size:                .5rem;
$rp-rating-dot-active-size:         .75rem;

$rp-rating-indicator-height:        1.125rem;
$rp-rating-indicator-gap:           .375rem;
$rp-rating-indicator-padding:       $rp-rating-indicator-gap * 2;
$rp-rating-indicator-dot-size:      .5rem;



// ############################################################################
// Mixins
// ############################################################################

@mixin rating-color($name, $color, $color-bg) {
  &[data-color="#{$name}"] {
    .rp-rating-label-value {
      color: $color;
    }

    .rp-rating-slider {
      .noUi-connect {
        background: $color;
      }

      .noUi-handle {
        border-color: $color;
        background: $color-bg;
      }

      .noUi-marker.active {
        background: $color;
      }
    }
  }
}



// ############################################################################
// Styles
// Complicated looking calculations deal with handle and pips positioning
// ############################################################################

.rp-rating-container {
  .rp-rating-label {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: add($label-margin-bottom, $rp-rating-slider-offset);
  }

  .rp-rating-label-value {
    @include font-settings($label-medium);
  }

  .rp-rating-slider {
    height: $rp-rating-slider-size;
    margin: $rp-rating-slider-offset divide($rp-rating-slider-handle-size, 2);

    background: $palette-blue-100;

    &[disabled] {
      opacity: .5;

      .noUi-base,
      .noUi-handle,
      .noUi-marker {
        cursor: not-allowed !important;
      }
    }

    .noUi-base {
      cursor: pointer;
    }

    .noUi-handle {
      width: $rp-rating-slider-handle-size;
      height: $rp-rating-slider-handle-size;

      top: -$rp-rating-slider-offset;
      right: divide(-$rp-rating-slider-handle-size, 2);

      border: $border-width solid $border-color;
      border-radius: 50%;

      cursor: pointer;
    }

    .noUi-pips {
      height: $rp-rating-dot-size;
      padding: 0;

      top: subtract(50%, divide($rp-rating-dot-size, 2));

      .noUi-marker {
        width: $rp-rating-dot-size;
        height: $rp-rating-dot-size;
        margin: 0;

        transform: translateX(divide(-$rp-rating-dot-size, 2));

        border-radius: 50%;
        background: $palette-blue-200;

        cursor: pointer;

        &.active {
          width: $rp-rating-dot-active-size;
          height: $rp-rating-dot-active-size;

          top: divide(subtract($rp-rating-dot-active-size, $rp-rating-dot-size), -2);
          transform: translateX(divide(-$rp-rating-dot-active-size, 2));
        }
      }

      .noUi-value {
        display: none;
      }
    }
  }

  @include rating-color("primary", $color-primary, $palette-blue-200);
  @include rating-color("error", $color-error, $color-error-var);
}

.rp-rating-indicator {
  display: flex;
  align-items: center;

  height: $rp-rating-indicator-height;
  padding: 0 $rp-rating-indicator-padding;
  gap: $rp-rating-indicator-gap;

  border-radius: divide($rp-rating-indicator-height, 2);
  background: $neutral-surface;

  .rp-rating-indicator-dot {
    width: $rp-rating-indicator-dot-size;
    height: $rp-rating-indicator-dot-size;

    border-radius: 50%;
    background: $neutral-surface-var-bg;

    &[data-color="primary"] {
      background: $color-primary;
    }

    &[data-color="error"] {
      background: $color-error;
    }

    &[data-color="success"] {
      background: $color-success;
    }
  }
}

.rp-table {
  .rp-rating-indicator {
    background: transparent;
  }
}
