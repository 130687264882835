@import "ResultList";
@import "ResultMessage";

.rp-select-dropdown {
  position: absolute;

  display: flex;
  flex-direction: column;

  overflow: hidden;
  user-select: none;

  color: $neutral-surface-bg;
  background: $neutral-surface;
  border: $border-width solid $border-color;
  box-shadow: $elevation-600;

  &:focus {
    outline: none;
  }

  &.top {
    border-top-left-radius: $card-border-radius;
    border-top-right-radius: $card-border-radius;
    padding-top: $card-border-radius;

    flex-direction: column-reverse;

    .rp-select-result-container {
      padding-bottom: $card-border-radius;
    }
  }

  &.bottom {
    border-bottom-left-radius: $card-border-radius;
    border-bottom-right-radius: $card-border-radius;
    padding-bottom: $card-border-radius;

    .rp-select-result-container {
      padding-top: $card-border-radius;
    }
  }

  .rp-select-selection {
    flex: 0 0 auto;
  }

  .rp-select-result-container {
    display: flex;
    flex-direction: column;

    flex: 1 1 auto;
    min-height: 0;
  }
}
