$rp-select-chip-height:         1.5rem;
$rp-select-chip-padding-x:      1rem;

.rp-select-chip {
  display: flex;
  align-items: center;

  position: relative;
  cursor: default;

  height: $rp-select-chip-height;
  padding: .25rem $rp-select-chip-padding-x;

  color: $palette-blue-400;
  background: $palette-blue-50;
  border-radius: divide($rp-select-chip-height, 2);

  &:hover:not(.disabled) {
    color: $palette-blue-500;
    background: $palette-blue-100;

    .rp-select-chip-text {
      transform: translateX(divide(-$rp-select-chip-padding-x, 2));
    }

    .rp-select-chip-close {
      opacity: 1;
    }
  }

  &.disabled {
    color: $palette-black-500;
    background: $palette-black-100;
  }

  @at-root {
    a#{&} {
      background: transparent;
      border: $border-width solid $palette-blue-300;

      cursor: pointer;

      &:hover {
        background: $palette-blue-50;
        border-color: $palette-blue-400;

        text-decoration: none;
      }

      &.disabled {
        background: transparent;
        border-color: $palette-black-400;

        &:hover {
          background: $palette-black-100;
          border-color: $palette-black-500;

          text-decoration: none;
        }
      }
    }
  }

  .rp-select-chip-text {
    transition: transform .2s ease-out;
  }

  .rp-select-chip-close {
    position: absolute;
    top: 50%;
    right: .25rem;
    margin-top: divide(-$icon-size-md, 2);

    font-size: $icon-size-md;
    cursor: pointer;

    opacity: 0;
    transition: opacity .2s ease-out;
  }
}
