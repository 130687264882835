@import "Chip";

.rp-select-chip-list {
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;

  gap: .25rem .5rem;
  padding: subtract(.25rem, $border-width) 0;

  @include font-settings($label-medium);

  .rp-select-placeholder {
    display: flex;
    align-items: center;

    color: $palette-black-500;
  }
}
