.user-menu {
  .dropdown-toggle {
    margin: 0 !important;
  }

  .dropdown-toggle-text {
    display: none;

    @include font-settings($title-medium);
  }

  .dropdown-toggle-icon {
    @include hide-caret();
  }

  .dropdown-item {
    padding: $dropdown-item-padding-y * 2 $dropdown-item-padding-x;
  }

  .dropdown-user-name {
    .dropdown-header {
      padding-top: $dropdown-divider-margin-y + $dropdown-item-padding-y;
    }
  }

  .dropdown-developer-name,
  .dropdown-developer-id {
    cursor: pointer;
  }

  @include media-breakpoint-up(xl) {
    .dropdown-toggle-text {
      display: block;
    }

    .dropdown-toggle-icon {
      display: none;
    }

    .dropdown-user-name {
      display: none;
    }

    .dropdown-developer-name,
    .dropdown-developer-id {
      .dropdown-header {
        padding-top: $dropdown-divider-margin-y + $dropdown-item-padding-y;
      }
    }
  }

  @include media-breakpoint-up(md) {
    & {
      margin-right: subtract($content-padding-x, $btn-padding-x);
    }
  }
}
