$rp-card-container-gap:               .75rem;
$rp-card-container-header-padding:    1rem;
$rp-card-container-bottom-padding:    1.5rem;

$rp-card-border-radius:               .5rem;

$rp-card-section-padding:             .75rem;
$rp-card-section-gap:                 .5rem;

$rp-card-action-padding:              .125rem;



.rp-interactive-card-container {
  padding-bottom: $rp-card-container-bottom-padding;

  .rp-card-container-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: $rp-card-container-gap;
    padding-bottom: $rp-card-container-header-padding;

    .rp-card-container-header-left {
      display: flex;
      flex-direction: column;

      gap: $rp-card-section-gap;
      padding-left: $rp-card-section-padding;

      .rp-card-container-title,
      .rp-card-container-subtitle {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .rp-card-container-title {
        color: $body-color;
        @include font-settings($title-medium);
      }

      .rp-card-container-subtitle {
        color: $neutral-surface-var-bg;
        @include font-settings($label-medium);
      }

      a {
        color: inherit;
      }
    }

    .rp-card-container-header-right {
      align-self: flex-start;
    }
  }

  .rp-interactive-card-container-body {
    display: flex;
    flex-direction: column;

    gap: $rp-card-container-gap;

    &:empty::before {
      content: attr(data-empty);
      text-align: center;

      color: $neutral-surface-var-bg;
      @include font-settings($label-medium);
    }
  }
}

.rp-interactive-card {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  position: relative;
  overflow: hidden;

  border: $border-width solid $border-color;
  border-radius: $rp-card-border-radius;
  background: $neutral-surface;

  &.with-hover-actions:hover,
  &[data-interactive-element]:hover {
    border-color: $palette-blue-300;
  }

  &[data-interactive-element] {
    cursor: pointer;

    &.active {
      border-color: transparent;
      background: $palette-blue-50 !important;
      box-shadow: $elevation-200-bottom;
    }
  }

  @include hover-actions();
}

.rp-card-section-properties {
  display: flex;
  flex-direction: column;

  padding: $rp-card-section-padding;
  gap: $rp-card-section-gap;
}

.rp-card-section-accordion-properties {
  .card {
    border-top: none;
    border-left: none;
    border-right: none;
  }

  .card-body {
    display: flex;
    flex-direction: column;

    padding: $rp-card-section-padding;
    gap: $rp-card-section-gap;
  }

  &:last-child {
    .card {
      border-bottom: none;
    }
  }

  & + .rp-card-section-note {
    border-top: none;
  }
}

.rp-card-property {
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: $rp-card-section-gap * 2;

  .rp-card-property-title {
    display: flex;
    align-items: center;

    flex-shrink: 0;
    white-space: nowrap;

    color: $neutral-surface-var-bg;
    @include font-settings($label-large);

    .rp-card-property-icon {
      font-size: $icon-size-md;
    }

    .rp-card-property-name {
      padding-left: $rp-card-section-gap;

      &:first-child {
        padding-left: add($icon-size-md, $rp-card-section-gap);
      }
    }
  }

  .rp-card-property-value {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    text-align: right;

    color: $neutral-surface-var-bg;
    @include font-settings($label-large);

    a {
      color: inherit;
    }
  }
}

.rp-card-section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: $rp-card-section-padding;
  gap: $rp-card-section-gap * 2;

  background: $neutral-surface-var-alt;
  border-bottom: $border-width solid $border-color-alt;

  .rp-card-header-title,
  .rp-card-header-subtitle {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .rp-card-header-title {
    color: $body-color;
    @include font-settings($body-large);
  }

  .rp-card-header-subtitle {
    text-align: right;

    color: $neutral-surface-var-bg;
    @include font-settings($label-medium);
  }

  a {
    color: inherit;
  }
}

.rp-card-section-contacts {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: $rp-card-section-padding;
  gap: $rp-card-section-gap * 2;

  .rp-card-contact {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    color: $neutral-surface-var-bg;
    @include font-settings($label-medium);

    &:not(:first-child) {
      text-align: right;
    }
  }
}

.rp-card-section-note {
  padding: $rp-card-section-padding;

  border-top: $border-width solid $border-color;

  color: $body-color;
  @include font-settings($body-large);

  &:first-child {
    border-top: none;
  }

  .rp-card-note {
    display: block;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .rp-card-note-multiline {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    overflow: hidden;
  }
}

.rp-card-section-badges {
  display: flex;
  align-items: center;

  padding: $rp-card-section-padding;
  gap: $rp-card-section-gap;

  border-bottom: $border-width solid $border-color;

  &:last-child {
    border-bottom: none;
  }

  & + .rp-card-section-note {
    border-top: none;
  }

  .rp-card-hover-badge {
    display: flex;
    align-items: center;

    gap: $rp-card-section-gap;

    .icon {
      font-size: $icon-size-xs;
    }
  }
}

.rp-card-section-button {
  overflow: hidden;

  .btn {
    border-radius: 0;
  }
}

.rp-card-section-detail {
  display: flex;
  flex-direction: column;

  padding: $rp-card-section-padding;
  gap: $rp-card-section-gap;

  .rp-card-detail-primary,
  .rp-card-detail-secondary {
    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: $rp-card-section-gap * 2;

    .rp-card-detail-label {
      flex-shrink: 0;
      white-space: nowrap;
    }

    .rp-card-detail-value {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      text-align: right;
    }
  }

  .rp-card-detail-primary {
    color: $body-color;
    @include font-settings($body-large);
  }

  .rp-card-detail-secondary {
    color: $neutral-surface-var-bg;
    @include font-settings($label-medium);
  }

  a {
    color: inherit;
  }
}

.rp-card-section-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: $rp-card-section-padding;
  gap: $rp-card-section-gap * 2;

  .rp-card-actions-left {
    display: flex;
    flex-direction: column;

    gap: $rp-card-section-gap;

    .rp-card-actions-title,
    .rp-card-actions-subtitle {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .rp-card-actions-title {
      color: $body-color;
      @include font-settings($body-large);
    }

    .rp-card-actions-subtitle {
      color: $neutral-surface-var-bg;
      @include font-settings($label-medium);
    }

    a {
      color: inherit;
    }
  }

  .rp-card-actions-right {
    display: flex;
    flex-wrap: wrap;
    align-self: flex-start;
    justify-content: flex-end;

    gap: $rp-card-section-gap;

    .btn {
      padding: $rp-card-action-padding;
      margin-right: -$rp-card-action-padding;
    }
  }
}

.rp-card-section-qc {
  padding: $rp-card-section-padding;

  & + .rp-card-section-note {
    border-top: none;
  }
}

.rp-card-section-rating {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: $rp-card-section-padding;
  gap: $rp-card-section-gap;

  border-top: $border-width solid $border-color;

  &:first-child {
    border-top: none;
  }
}
