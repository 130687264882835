@import "ResultItem";

.rp-select-result-list {
  display: flex;
  flex-direction: column;

  flex: 1 1 auto;
  min-height: 0;
  max-height: 16rem;

  overflow: auto;
  overscroll-behavior: contain;

  color: $neutral-surface-bg;
  background: $neutral-surface;

  @include font-settings($label-medium);

  &.has-scrollbar {
    margin-right: divide($scrollbar-size, 2);
    padding-right: divide($scrollbar-size, 2);
  }
}
