$rp-select2-clear-size:         $icon-size-md;
$rp-select2-clear-margin:       .25rem;



@import "~select2/src/scss/core";

@import "single";
@import "multiple";



.select2-container {
  display: block;

  font-family: $input-font-family;
  font-weight: $input-font-weight;
  @include font-size($input-font-size);

  *:focus {
    outline: 0;
  }
}

.select2-container--realpad {
  .select2-selection {
    display: flex;

    background-color: $input-bg;
    border: $input-border-width solid $input-border-color;
    @include border-radius($input-border-radius);
    @include box-shadow($input-box-shadow);
    @include transition($input-transition);

    .select2-selection__rendered {
      flex: 1 1 auto;
    }
  }

  &.select2-container--open {
    z-index: $zindex-popover + 1;
  }

  &.select2-container--open.select2-container--above {
    .select2-selection {
      @include border-top-radius(0);
    }
  }

  &.select2-container--open.select2-container--below {
    .select2-selection {
      @include border-bottom-radius(0);
    }
  }

  &.select2-container--focus {
    &:not(.select2-container--open):not(.select2-container--disabled) {
      .select2-selection {
        border-color: $input-focus-border-color;
        box-shadow: $input-focus-box-shadow;
      }
    }
  }

  &.select2-container--disabled {
    .select2-selection {
      cursor: not-allowed;
      background-color: $input-disabled-bg;
      border-color: $input-border-color;
      @include box-shadow(none);
    }
    .select2-search__field {
      background-color: transparent;
    }
  }

  select.is-invalid ~ & .select2-selection,
  form.was-validated select:invalid ~ & .select2-selection {
    border-color: $danger;
  }
  select.is-valid ~ & .select2-selection,
  form.was-validated select:valid ~ & .select2-selection {
    border-color: $success;
  }

  .select2-dropdown {
    border: $input-border-width solid $input-border-color;
    @include border-radius($input-border-radius);

    &.select2-dropdown--below {
      border-top: none;
      @include border-top-radius(0);
    }

    &.select2-dropdown--above {
      border-bottom: none;
      @include border-bottom-radius(0);
    }
  }

  .select2-search--dropdown {
    padding: $input-padding-y * .5;

    .select2-search__field {
      padding: $input-padding-y * .5 subtract(subtract($input-padding-x, $input-padding-y * .5), $input-border-width);

      border: $input-border-width solid $input-border-color;
      @include border-radius($input-border-radius);

      @include form-control-focus($ignore-warning: true);
    }
  }

  .select2-results > .select2-results__options {
    max-height: 20em;
    overflow-y: auto;
  }

  .select2-results__option {
    padding: $input-padding-y $input-padding-x;

    &[aria-disabled=true] {
      color: $disabled;
    }

    &[aria-selected=true] {
      background-color: $input-border-color;
    }
  }

  .select2-results__option--highlighted[aria-selected] {
    background-color: $primary;
    color: $light;
  }

  .select2-results__option[role=group] {
    padding: 0;

    .select2-results__options--nested .select2-results__option {
      padding-left: $input-padding-x * 2;
    }
  }

  .select2-results__group {
    display: list-item;
    padding: $input-padding-y $input-padding-x;
  }

  .select2-results__message {
    color: $gray-500;
  }

  .select2-selection__clear {
    width: $rp-select2-clear-size;
    height: $rp-select2-clear-size;
    margin: $rp-select2-clear-margin;

    font-size: $rp-select2-clear-size;
    color: $gray-500;

    cursor: pointer;

    &:hover {
      color: $secondary;
    }
  }
}

.input-group .select2-container--realpad {
  flex-grow: 1;
  min-width: 0;
}
.input-group-prepend ~ .select2-container--realpad .select2-selection {
  @include border-left-radius(0);
}
.input-group > .select2-container--realpad:not(:last-child) .select2-selection {
  @include border-right-radius(0);
}
