.select2-container--realpad {
  .select2-selection--single {
    align-items: center;
    height: $input-height;

    .select2-selection__rendered {
      padding: 0 $input-padding-x;
      line-height: $input-height-inner;
    }

    .select2-selection__placeholder {
      color: $input-placeholder-color;
    }

    .select2-selection__arrow {
      font-size: $rp-select2-clear-size;
      margin: $rp-select2-clear-margin;
    }

    .select2-selection__clear + .select2-selection__arrow {
      margin-left: 0;
    }
  }

  &.select2-container--disabled {
    .select2-selection--single {
      .select2-selection__clear {
        display: none;
      }
    }
  }
}
