.nav-tabs {
  .nav-link {
    display: flex;
    align-items: center;
    justify-content: center;

    border-top: none;
    border-left: none;
    border-right: none;

    color: inherit;

    &.nav-link-info {
      color: $info;

      @include hover-focus() {
        border-color: $info;
      }
    }

    &.nav-link-danger {
      color: $danger;

      @include hover-focus() {
        border-color: $danger;
      }
    }

    .icon {
      font-size: $icon-size-md;
      margin-right: $spacer * .25;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    position: relative;

    &::after {
      content: "";
      height: $nav-tabs-border-width * 2;

      position: absolute;
      bottom: -$nav-tabs-border-width * 1.5;
      left: 0;
      right: 0;

      background: $nav-tabs-link-active-border-color;
    }

    &.nav-link-info::after {
      background: $info;
    }

    &.nav-link-danger::after {
      background: $danger;
    }
  }
}
