$rp-radio-btn-padding:      .25rem;
$rp-radio-btn-gap:          .75rem;



.rp-radio-btn-group {
  display: flex;

  padding-top: $rp-radio-btn-padding;
  padding-bottom: $rp-radio-btn-padding;
  gap: $rp-radio-btn-gap;

  &.disabled {
    background: $input-disabled-bg;
  }
}

.rp-radio-btn {
  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;

  flex: 1 !important;

  border-radius: $btn-border-radius !important;

  @include button-style($neutral-surface-var-bg, transparent, transparent, $neutral-surface-var-bg, $palette-black-100, $palette-black-100, $neutral-surface-var-bg, transparent, transparent);

  &.active {
    &.rp-radio-btn-primary {
      @include button-style($neutral-surface, $palette-blue-500, $palette-blue-500, $neutral-surface, $palette-blue-400, $palette-blue-400, $neutral-surface, $palette-blue-500, $palette-blue-500);
    }

    &.rp-radio-btn-error {
      @include button-style($neutral-surface, $color-error, $color-error, $neutral-surface, lighten($color-error, 5%), lighten($color-error, 5%), $neutral-surface, $color-error, $color-error);
    }

    &.rp-radio-btn-success {
      @include button-style($neutral-surface, $color-success, $color-success, $neutral-surface, lighten($color-success, 5%), lighten($color-success, 5%), $neutral-surface, $color-success, $color-success);
    }
  }

  .rp-radio-btn-icon {
    padding-right: $rp-radio-btn-padding;
    font-size: $icon-size-sm;
  }

  .rp-radio-btn-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
