@import "~nouislider/dist/nouislider.css";

$slider-size:           .25rem;
$slider-handle-size:    1rem;
$slider-input-size:     10em;
$slider-margin:         1rem;

.noUi-horizontal {
  height: $slider-size;

  .noUi-handle {
    width: $slider-handle-size;
    height: $slider-handle-size;

    top: -($slider-handle-size * .5 - $slider-size * .5);
    right: -($slider-handle-size * .5);
  }
}

.noUi-vertical {
  width: $slider-size;

  .noUi-handle {
    width: $slider-handle-size;
    height: $slider-handle-size;

    top: -($slider-handle-size * .5);
    right: -($slider-handle-size * .5 - $slider-size * .5);
  }
}

.noUi-target {
  border: none;
  border-radius: 0;

  background: $border-color;
  box-shadow: none;
}

.noUi-connects {
  border-radius: 0;

  .noUi-connect {
    background: $primary;
  }
}

.noUi-handle {
  border: $border-width * 2 solid $primary;
  border-radius: $slider-handle-size * .5;

  background: $white;
  box-shadow: none;

  &:focus {
    outline: none;
  }

  &::before,
  &::after {
    display: none;
  }

  &.noUi-active {
    background: $primary;
    box-shadow: none;
  }
}

[disabled] {
  .noUi-connect {
    background: $disabled;
  }

  .noUi-handle {
    border-color: $disabled;
  }
}

.slider-container {
  display: flex;
  align-items: center;

  .slider-min,
  .slider-max {
    width: $slider-input-size;
  }

  .slider {
    flex-grow: 1;

    margin: 0 $slider-margin;
  }
}
