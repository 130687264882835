.deal-close-buttons {
  display: flex;
  align-items: center;

  .deal-close-buttons-separator {
    height: $spacer;
    margin: $spacer;

    border-left: $border-width solid $border-color;
  }

  .deal-closing-reason {
    width: 10rem;
  }

  & > .btn:not(:first-child) {
    margin-left: $spacer * .5;
  }
}
