// ############################################################################
// Styles
// ############################################################################

.badge {
  display: inline-flex;
  @include font-settings($label-small);
  gap: $badge-gap;
  align-items: center;

  .badge-icon {
    font-size: $icon-size-xs;
  }
}

.badge-container {
  display: flex;
  flex-wrap: wrap;
  gap: $badge-gap;
}

@each $color, $value in $status-colors {
  .color-status-#{$color} {
    color: $neutral-surface;
    background-color: $value;
  }
}

.color-status-cancelled {
  color: $color-error;
  background-color: $color-error-var;

  .badge-text {
    text-decoration: line-through;
  }
}
