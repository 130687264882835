$rp-modal-content-margin-y:         .75rem;
$rp-modal-content-margin-x:         1rem;
$rp-modal-body-margin-y:            1.125rem;

$rp-modal-header-gap:               1rem;
$rp-modal-footer-gap:               1rem;
$rp-modal-footer-padding-top:       .75rem;
$rp-modal-footer-buttons-gap:       .75rem;

$rp-modal-alerts-gap:               .75rem;



.modal-dialog {
  position: relative;
}

.modal-body {
  margin: divide($rp-modal-body-margin-y, 2) divide($rp-modal-content-margin-x, 2);
  padding: divide($rp-modal-body-margin-y, 2) divide($rp-modal-content-margin-x, 2);

  & > *:last-child {
    margin-bottom: 0 !important;
  }
}

.modal-header-container {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  margin: $rp-modal-content-margin-y $rp-modal-content-margin-x 0;
  gap: $rp-modal-header-gap;

  &:empty {
    display: none;
  }

  .modal-header {
    align-items: center;

    .modal-title {
      @include font-settings($title-medium);
    }
  }

  .modal-alerts {
    padding-bottom: $rp-modal-header-gap;
    border-bottom: $border-width solid $color-primary-bg;
  }
}

.modal-footer-container {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  margin: 0 $rp-modal-content-margin-x $rp-modal-content-margin-y;
  gap: $rp-modal-footer-gap;

  &:empty {
    display: none;
  }

  .modal-footer {
    flex-wrap: nowrap;
    justify-content: space-between;

    padding-top: $rp-modal-footer-padding-top;
    border-top: $border-width solid $color-primary-bg;

    .modal-footer-buttons {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      gap: $rp-modal-footer-buttons-gap;
    }
  }

  .modal-alerts {
    padding-top: $rp-modal-footer-gap;
    border-top: $border-width solid $color-primary-bg;
  }
}

.modal-alerts {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  gap: $rp-modal-alerts-gap;

  &:empty {
    display: none;
  }

  .rp-alert {
    margin: 0;
  }
}
