// ############################################################################
// Mixins
// ############################################################################

@mixin button-style($color, $background, $border, $hover-color, $hover-background, $hover-border, $active-color, $active-background, $active-border) {
  color: $color;
  background: $background;
  border-color: $border;

  &:hover {
    color: $hover-color;
    background: $hover-background;
    border-color: $hover-border;
  }

  &:focus,
  &.focus {
    color: $hover-color;
    background: $hover-background;
    border-color: $hover-border;

    &:not(.dropdown-item) {
      box-shadow: 0 0 0 $btn-focus-width rgba($hover-background, .5);
    }
  }

  &:disabled,
  &.disabled {
    color: $color;
    background-color: $background;
    border-color: $border;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $active-color;
    background-color: $active-background;
    border-color: $active-border;

    &:focus,
    &.focus {
      box-shadow: 0 0 0 $btn-focus-width rgba($active-background, .5);
    }
  }
}

@mixin button-icon-size($line-height, $icon-size, $icon-text-gap) {
  .btn-icon,
  .btn-icon-right {
    font-size: $icon-size;
  }

  .btn-icon + .btn-text,
  .btn-text + .btn-icon-right,
  .btn-loading-icon + .btn-loading-text {
    margin-left: $icon-text-gap;
  }

  .btn-loading-icon {
    width: $line-height;
    height: $line-height;
  }
}

// ############################################################################
// Styles
// ############################################################################

.btn {
  display: inline-flex;
  align-items: center;

  position: relative;

  @include button-icon-size($btn-line-height, $btn-icon-size, $btn-icon-text-gap);

  &.btn-sm {
    @include button-icon-size($btn-line-height-sm, $btn-icon-size-sm, $btn-icon-text-gap-sm);
  }

  &.btn-lg {
    @include button-icon-size($btn-line-height-lg, $btn-icon-size-lg, $btn-icon-text-gap-lg);
  }

  .btn-text {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &.btn-block {
    display: flex;

    .btn-text,
    .btn-loading-text {
      flex: 1;

      justify-content: flex-start;
    }

    &.btn-text-center {
      .btn-text,
      .btn-loading-text {
        justify-content: center;
      }
    }
  }

  .btn-loading-icon {
    border-width: $btn-loader-width;
  }

  &.loading {
    .btn-icon,
    .btn-text {
      display: none;
    }
  }

  &:not(.loading) {
    .btn-loading-icon,
    .btn-loading-text {
      display: none;
    }
  }

  .btn-disabled-tooltip {
    display: none;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }

  &:disabled:not(.loading) {
    .btn-disabled-tooltip {
      display: block;
    }
  }
}

.btn-primary-filled {
  @include button-style($neutral-surface, $palette-blue-500, $palette-blue-500, $neutral-surface, $palette-blue-400, $palette-blue-400, $neutral-surface, $palette-blue-700, $palette-blue-700);

  &.dropdown-toggle-split {
    &::before {
      content: '';

      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;

      margin: $btn-padding-y 0;

      border-left: $border-width solid $palette-blue-400;
    }

    &.btn-sm::before {
      margin: $btn-padding-y-sm 0;
    }

    &.btn-lg::before {
      margin: $btn-padding-y-lg 0;
    }

    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    .show > & {
      &::before {
        display: none;
      }
    }
  }
}

.btn-secondary-filled {
  @include button-style($neutral-surface, $palette-black-800, $palette-black-800, $neutral-surface, $palette-black-700, $palette-black-700, $neutral-surface, $palette-black-900, $palette-black-900);
}

.btn-danger-filled {
  @include button-style($neutral-surface, $color-error, $color-error, $neutral-surface, lighten($color-error, 5%), lighten($color-error, 5%), $neutral-surface, darken($color-error, 10%), darken($color-error, 10%));
}

.btn-primary-outline {
  @include button-style($palette-blue-500, transparent, $palette-blue-300, $palette-blue-500, $palette-blue-50, $palette-blue-400, $palette-blue-700, $palette-blue-200, $palette-blue-700);
}

.btn-secondary-outline {
  @include button-style($palette-black-1000, transparent, $palette-black-600, $palette-black-1000, $palette-black-100, $palette-black-700, $palette-black-1000, $palette-black-500, $palette-black-1000);
}

.btn-danger-outline {
  @include button-style($color-error, transparent, lighten($color-error, 10%), $color-error, lighten($color-error, 25%), lighten($color-error, 5%), darken($color-error, 10%), lighten($color-error, 15%), darken($color-error, 10%));
}

.btn-primary-text {
  @include button-style($palette-blue-500, transparent, transparent, $palette-blue-500, $palette-blue-50, $palette-blue-50, $palette-blue-500, $palette-blue-200, $palette-blue-200);
}

.btn-secondary-text {
  @include button-style($palette-black-1000, transparent, transparent, $palette-black-1000, $palette-black-100, $palette-black-100, $palette-black-1000, $palette-black-500, $palette-black-500);
}

.btn-danger-text {
  @include button-style($color-error, transparent, transparent, $color-error, lighten($color-error, 25%), lighten($color-error, 25%), $color-error, lighten($color-error, 15%), lighten($color-error, 15%));
}

.btn-alternative {
  @include button-style($neutral-surface, $neutral-surface-var-bg, $neutral-surface-var-bg, $neutral-surface, $palette-black-1000, $palette-black-1000, $neutral-surface, $palette-black-900, $palette-black-900);
}

.btn-dropdown-item {
  @include button-style($palette-black-1000, $neutral-surface, $neutral-surface, $palette-black-1000, $palette-blue-50, $palette-blue-50, $palette-black-1000, $palette-blue-200, $palette-blue-200);
}

.btn-link {
  font-weight: $btn-font-weight;

  &:disabled:not(a),
  &.disabled:not(a) {
    pointer-events: auto;
    text-decoration: none;
  }
}
