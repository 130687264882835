$rp-select-result-text-padding:     .625rem 0;

.rp-select-result {
  padding: 0 .75rem;

  cursor: pointer;

  &.selected {
    background: $palette-blue-100;
  }

  &.highlighted {
    background: $palette-blue-50;
  }

  &.disabled {
    cursor: default;

    color: $palette-black-500;
    background: $neutral-surface;
  }

  .rp-select-result-text {
    padding: $rp-select-result-text-padding;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.secondary {
      color: $neutral-surface-var-bg;
    }
  }

  .rp-select-result-text-pair {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .rp-select-result-header {
    padding: .5rem 0 .25rem;
    margin: 0 0 .25rem;

    cursor: default;

    color: $neutral-surface-var-bg;
    border-bottom: $border-width solid $palette-black-500;

    @include font-settings($label-small);
  }
}

.rp-select-dropdown.narrow {
  .rp-select-result {
    .rp-select-result-text-pair {
      flex-direction: column;
      align-items: flex-start;

      padding: $rp-select-result-text-padding;

      .rp-select-result-text {
        padding: 0;

        &.secondary {
          @include font-settings($label-small);

          &:empty {
            display: none;
          }
        }
      }
    }
  }
}
