$rp-list-border-radius:                   $card-border-radius;

$rp-list-header-height:                   4.25rem;
$rp-list-header-padding-x:                1rem;
$rp-list-header-padding-y:                .75rem;
$rp-list-header-gap:                      1rem;
$rp-list-header-text-gap:                 .25rem;

$rp-list-footer-padding-x:                1rem;
$rp-list-footer-padding-y:                .75rem;
$rp-list-footer-gap:                      .75rem;

$rp-list-alerts-padding-x:                1rem;
$rp-list-alerts-padding-y:                .75rem;
$rp-list-alerts-gap:                      .75rem;

$rp-list-items-spacing:                   1rem;
$rp-list-items-expanded-spacing:          .5rem;

$rp-list-item-padding-x:                  1rem;
$rp-list-item-padding-y:                  .375rem;
$rp-list-item-horizontal-gap:             1rem;
$rp-list-item-vertical-gap:               .25rem;
$rp-list-item-border-radius:              .375rem;

$rp-list-item-actions-offset:             .25rem;
$rp-list-item-actions-padding-x:          .5rem;
$rp-list-item-actions-padding-y:          .25rem;
$rp-list-item-actions-gap:                .5rem;
$rp-list-item-actions-border-radius:      .25rem;



.rp-interactive-list {
  display: flex;
  flex-direction: column;

  overflow: hidden;

  margin-bottom: $spacer;

  border: $border-width solid $border-color;
  border-radius: $rp-list-border-radius;
  background: $neutral-surface;

  .rp-interactive-list-header {
    display: flex;
    align-items: stretch;

    flex: 0 0 $rp-list-header-height;
    padding: $rp-list-header-padding-y $rp-list-header-padding-x;
    gap: $rp-list-header-gap;

    background: $neutral-surface-var-alt;
    border-bottom: $border-width solid $border-color-alt;

    > .divider {
      border-left: $border-width solid $border-color;
    }

    .rp-interactive-list-title {
      display: flex;
      flex-direction: column;
      justify-content: center;

      gap: $rp-list-header-text-gap;

      > .title {
        @include font-settings($title-medium);
      }

      > .subtitle {
        @include font-settings($label-medium);

        color: $neutral-surface-var-bg;
      }
    }

    .rp-interactive-list-additional-info {
      display: flex;
      flex-direction: column;
      justify-content: center;

      gap: $rp-list-header-text-gap;

      > .title {
        @include font-settings($label-large);
      }

      > .subtitle {
        @include font-settings($label-medium);

        color: $neutral-surface-var-bg;
      }
    }

    .rp-interactive-list-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      flex: 1;
    }
  }

  .rp-interactive-list-footer {
    display: flex;
    flex-direction: column;

    padding: $rp-list-footer-padding-y $rp-list-footer-padding-x;
    gap: $rp-list-footer-gap;

    background: $neutral-surface-var-alt;

    > .divider {
      border-top: $border-width solid $border-color;

      & + .divider {
        display: none;
      }
    }
  }

  .rp-interactive-list-alerts {
    display: flex;
    flex-direction: column;

    padding: $rp-list-alerts-padding-y $rp-list-alerts-padding-x;
    gap: $rp-list-alerts-gap;

    &:empty {
      display: none;
    }

    .rp-alert {
      margin: 0;
    }
  }

  .rp-interactive-list-items {
    display: flex;
    flex-direction: column;
    flex: 1;

    overflow: auto;

    margin: divide($rp-list-items-spacing, 2);
    padding: divide($rp-list-items-spacing, 2);
    gap: $rp-list-items-spacing;

    > .divider {
      border-top: $border-width solid $border-color;

      & + .divider {
        display: none;
      }
    }

    &.expanded-items {
      margin: $rp-list-items-expanded-spacing 0;
      padding: 0;
      gap: $rp-list-items-expanded-spacing;

      .rp-interactive-list-item {
        border: none;
        border-radius: 0;
      }
    }
  }

  .rp-interactive-list-loader,
  .rp-interactive-list-error {
    padding: $rp-list-items-spacing;
    margin: auto;

    text-align: center;
  }

  .rp-interactive-list-loader {
    color: $color-primary;
  }

  .rp-interactive-list-error {
    @include font-settings($title-medium);

    color: $color-error;
  }
}

.rp-interactive-list-item {
  position: relative;
  overflow: hidden;

  flex-shrink: 0;

  border: $border-width solid $border-color;
  border-radius: $rp-list-item-border-radius;
  background: $neutral-surface;

  &.with-hover-actions:hover,
  &[data-interactive-element]:hover {
    border-color: $palette-blue-300;
    background: $neutral-surface-var;
  }

  &[data-interactive-element] {
    cursor: pointer;

    &.active {
      border-color: transparent;
      background: $palette-blue-50 !important;
      box-shadow: $elevation-200-bottom;
    }
  }

  &.rp-interactive-list-item-horizontal {
    .rp-interactive-list-item-header {
      padding: $rp-list-item-padding-y * 2 $rp-list-item-padding-x;
      border-bottom: $border-width solid $border-color;

      .rp-qc-section {
        max-width: 50%;
      }
    }

    .rp-interactive-list-item-containers {
      display: flex;
      align-items: stretch;

      padding: $rp-list-item-padding-y $rp-list-item-padding-x;
      gap: $rp-list-item-horizontal-gap;

      @for $i from 1 through 6 {
        > .weight-#{$i} {
          flex: #{$i};

          // required for proper content overflow and keeping correct width
          min-width: 0;
        }
      }

      > .divider {
        margin: divide($rp-list-item-padding-y, 2) 0;
        border-left: $border-width solid $border-color;
      }
    }
  }

  &.rp-interactive-list-item-vertical {
    .rp-interactive-list-item-containers,
    .rp-interactive-list-item-footer-containers {
      display: flex;
      flex-direction: column;

      padding: $rp-list-item-padding-y $rp-list-item-padding-x;
      gap: $rp-list-item-vertical-gap;

      &:empty {
        display: none;
      }

      > .divider {
        border-top: $border-width solid $border-color;
      }
    }

    .rp-interactive-list-item-footer-containers {
      background: $neutral-surface-var-alt;
    }

    .rp-interactive-list-item-footer-note {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      padding: add($rp-list-item-padding-y, $rp-list-item-vertical-gap) $rp-list-item-padding-x;

      @include font-settings($label-medium);

      background: $neutral-surface-var-alt;
    }
  }

  @include hover-actions();
}
