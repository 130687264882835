$rp-select2-multiple-selection-gap:               .25rem .5rem;
$rp-select2-multiple-selection-padding:           subtract(.25rem, $input-border-width) $input-padding-x;

$rp-select2-multiple-choice-height:               1.5rem;
$rp-select2-multiple-choice-padding-y:            .25rem;
$rp-select2-multiple-choice-padding-x:            1rem;
$rp-select2-multiple-choice-remove-offset-right:  .25rem;

$rp-select2-multiple-clear-margin-top:            .375rem;



.select2-container--realpad {
  .select2-selection--multiple {
    min-height: $input-height;

    .select2-selection__rendered {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      padding: $rp-select2-multiple-selection-padding;
      gap: $rp-select2-multiple-selection-gap;
    }

    .select2-selection__choice {
      display: flex;
      align-items: center;

      position: relative;

      height: $rp-select2-multiple-choice-height;
      padding: $rp-select2-multiple-choice-padding-y $rp-select2-multiple-choice-padding-x;

      background: $palette-blue-50;
      border-radius: divide($rp-select2-multiple-choice-height, 2);

      @include font-settings($label-medium);
      color: $palette-blue-400;

      cursor: default;

      &:hover {
        background: $palette-blue-100;
        color: $color-primary;

        .select2-selection__choice__text {
          transform: translateX(divide(-$rp-select2-multiple-choice-padding-x, 2));
        }

        .select2-selection__choice__remove {
          opacity: 1;
        }
      }

      &.select2-selection__choice__link {
        background: transparent;
        border: $border-width solid $palette-blue-300;

        cursor: pointer;

        &:hover {
          background: $palette-blue-50;
          border-color: $palette-blue-400;

          text-decoration: none;
        }
      }

      &.select2-selection__choice__locked {
        .select2-selection__choice__text {
          transform: none !important;
        }

        .select2-selection__choice__remove {
          display: none;
        }
      }
    }

    .select2-selection__choice__text {
      transition: transform .2s ease-out;
    }

    .select2-selection__choice__remove {
      position: absolute;
      top: 50%;
      right: $rp-select2-multiple-choice-remove-offset-right;
      margin-top: divide(-$rp-select2-clear-size, 2);

      font-size: $rp-select2-clear-size;
      cursor: pointer;

      opacity: 0;
      transition: opacity .2s ease-out;
    }

    .select2-selection__clear {
      margin-top: $rp-select2-multiple-clear-margin-top;
    }

    .select2-search__field {
      margin: 0;

      &::placeholder {
        color: $input-placeholder-color;
      }
    }
  }

  &.select2-container--disabled {
    .select2-selection--multiple {
      .select2-selection__clear {
        display: none;
      }

      .select2-selection__choice__text {
        transform: none !important;
      }

      .select2-selection__choice__remove {
        display: none;
      }
    }
  }

  .select2-helper-buttons {
    display: block;
    padding: $input-padding-y $input-padding-x;

    .select2-helper-button {
      cursor: pointer;
      font-weight: $font-weight-bold;

      &:first-child {
        margin-right: $input-padding-y * 2;
      }
    }
  }
}
