$confirmation-styles: (
  "primary": $primary,
  "success": $success,
  "warning": $warning,
  "danger": $danger
);

.popover-confirmation {
  .popover-body {
    padding: .5rem .75rem;
  }

  .confirmation-message {
    display: flex;
    align-items: center;
    gap: .25rem;
    word-break: break-word;

    padding: $btn-padding-x;

    font-size: $font-size-lg;

    .icon {
      margin-right: $spacer * .5;
    }
  }

  .confirmation-buttons {
    display: flex;
    justify-content: space-between;

    .confirmation-confirm {
      color: $primary;
    }

    .confirmation-cancel {
      color: $secondary;
    }
  }

  @each $color, $value in $confirmation-styles {
    &.confirmation-#{$color} {
      border-color: $value;

      &.bs-popover-top,
      &.bs-popover-auto[x-placement^="top"] {
        > .arrow {
          &::before {
            border-top-color: $value;
          }
        }
      }

      &.bs-popover-right,
      &.bs-popover-auto[x-placement^="right"] {
        > .arrow {
          &::before {
            border-right-color: $value;
          }
        }
      }

      &.bs-popover-bottom,
      &.bs-popover-auto[x-placement^="bottom"] {
        > .arrow {
          &::before {
            border-bottom-color: $value;
          }
        }
      }

      &.bs-popover-left,
      &.bs-popover-auto[x-placement^="left"] {
        > .arrow {
          &::before {
            border-left-color: $value;
          }
        }
      }

      .confirmation-buttons {
        .confirmation-confirm {
          color: $value;
        }
      }
    }
  }
}
