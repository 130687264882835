$task-popover-width:    18rem;

.rp-task-popover {
  width: $task-popover-width;
  padding: .75rem 1rem;

  .rp-task-popover-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-bottom: 1rem;

    .rp-task-popover-header-color {
      width: .875rem;
      height: .875rem;

      background: $color-primary;
      border-radius: .25rem;
    }

    .rp-task-popover-header-actions {
      display: flex;
      align-items: center;

      gap: .5rem;

      .btn {
        color: $neutral-surface-var-bg;
      }
    }
  }

  .rp-task-popover-title {
    margin: 0;
    padding-bottom: 1.5rem;

    @include font-settings($title-medium);
  }

  .rp-task-popover-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: .75rem;
    padding-top: .75rem;

    border-top: $border-width solid $border-color;

    .btn {
      justify-content: center;

      width: divide($task-popover-width - 1rem, 2);
    }
  }

  &.update {
    &:not(.modified) {
      .rp-task-popover-buttons {
        .rp-task-popover-button-save {
          display: none;
        }
      }
    }

    &.modified {
      .rp-task-popover-buttons {
        .btn:not(.rp-task-popover-button-save) {
          display: none;
        }
      }
    }
  }
}
