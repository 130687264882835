@import "Selection";
@import "Dropdown";

.rp-select {
  flex: 1 1 auto;

  color: $neutral-surface-bg;
  background: $neutral-surface;
  border: $border-width solid $border-color;
  outline: none;
}

.rp-select-input.is-invalid + .rp-select {
  border-color: $color-error;
}

.rp-select-input:disabled + .rp-select {
  color: $palette-black-500;
  border-color: $palette-black-400;
}

.rp-select-input:not(:disabled) + .rp-select {
  &:hover {
    border-color: $palette-purple-300;
  }

  &:focus,
  &:active {
    border-color: $input-focus-border-color;
    box-shadow: $input-focus-box-shadow;
  }
}
