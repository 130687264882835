.centered-form-with-logo {
  width: 100%;
  max-width: 20rem;

  margin: auto;
  padding: 2rem 0;

  .logo {
    width: 100%;
    margin-bottom: 2rem;
  }
}
