.rp-select-inline-search {
  display: inline-flex;
  position: relative;

  height: $rp-select-chip-height;
  @include font-settings($label-medium);

  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    padding: 0;

    border: none;
    outline: none;
  }

  &::after {
    content: attr(data-value);
    visibility: hidden;

    padding: 0 .125rem;
    white-space: pre;
  }
}
