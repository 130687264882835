$rp-qc-padding-y:             .25rem;
$rp-qc-margin-top:            .625rem;
$rp-qc-gap:                   .375rem;

$rp-qc-tab-padding-x:         .75rem;
$rp-qc-tab-padding-y:         .25rem;
$rp-qc-tab-border-radius:     .25rem;

$rp-qc-card-spacing:          .75rem;
$rp-qc-card-border-radius:    .5rem;



.rp-qc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: $rp-qc-padding-y 0;
}

.rp-qc-row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: $rp-qc-gap;

  color: $neutral-surface-var-bg;

  &:first-child {
    height: $label-large-line-height;

    color: $neutral-surface-bg;
  }

  & + & {
    margin-top: $rp-qc-margin-top;
  }

  .rp-qc-collapsible &.rp-qc-row-empty {
    display: none;
  }

  .rp-qc-collapsible &.rp-qc-row-empty + & {
    margin-top: 0;
  }
}

.rp-qc-section {
  display: flex;
  flex-wrap: nowrap;

  min-width: 20%;

  &:not(.rp-qc-section-tab) {
    overflow: hidden;
  }

  &:first-child {
    text-align: left;
    justify-content: flex-start;
  }

  &:last-child {
    text-align: right;
    justify-content: flex-end;
  }

  &.rp-qc-font-regular {
    .rp-qc-text {
      @include font-settings($label-medium);
    }

    > .icon {
      font-size: $icon-size-sm;
    }
  }

  &.rp-qc-font-large {
    .rp-qc-text {
      @include font-settings($label-large);
    }

    > .icon {
      font-size: $icon-size-md;
    }
  }

  .rp-qc-text {
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;

    min-width: 0;
  }

  > .icon {
    color: $neutral-surface-var-bg;
  }

  .rp-qc-text + :not(.rp-qc-text) {
    margin-left: $rp-qc-gap;
  }

  > :not(.rp-qc-text, .rp-qc-input) + .rp-qc-text {
    margin-left: $rp-qc-gap;
  }

  > :not(.rp-qc-text, .rp-qc-input) + :not(.rp-qc-text) {
    margin-left: $rp-qc-gap;
  }
}

.rp-qc-tab {
  padding: $rp-qc-tab-padding-y $rp-qc-tab-padding-x;
  margin: -$rp-qc-tab-padding-y 0;

  color: $palette-blue-400;
  background: $palette-blue-50;
  border-radius: $rp-qc-tab-border-radius;

  @include font-settings($label-medium);
}

.rp-qc-card {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  margin-bottom: $rp-qc-card-spacing;
  padding: $rp-qc-card-spacing;
  gap: $rp-qc-card-spacing;

  border: $border-width solid $border-color;
  border-radius: $rp-qc-card-border-radius;
  background: $neutral-surface;

  > .divider {
    border-top: $border-width solid $border-color;
  }

  .rp-qc-card-note {
    @include font-settings($label-medium);
  }

  &.rp-qc-card-input {
    cursor: pointer;

    &.checked {
      border-color: $palette-blue-300;

      .rp-qc-toggle-icon {
        color: $color-primary;
      }
    }
  }
}
