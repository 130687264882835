.rp-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  width: $icon-size-lg;
  height: $icon-size-lg;
  border-radius: 50%;

  color: $neutral-surface;
  background: $color-primary;

  @include font-settings($label-large);

  &.rp-avatar-md {
    width: $icon-size-md;
    height: $icon-size-md;

    @include font-settings($label-medium);
  }
}
