$rp-tooltip-font:                     $label-large;
$rp-tooltip-color:                    $neutral-surface;
$rp-tooltip-background:               $neutral-surface-bg;

$rp-tooltip-max-width:                20rem;
$rp-tooltip-max-width-large:          36rem;
$rp-tooltip-padding:                  .375rem .75rem;

$rp-tooltip-box-shadow:               $elevation-600;
$rp-tooltip-border-radius:            .25rem;

$rp-tooltip-arrow-width:              .5rem;
$rp-tooltip-arrow-height:             .25rem;



.rp-tooltip {
  position: absolute;
  z-index: $zindex-tooltip;

  word-wrap: break-word;
  pointer-events: none;

  &.rp-tooltip-enter-active,
  &.rp-tooltip-leave-active {
    transition: opacity .15s linear;
  }

  &.rp-tooltip-enter-from,
  &.rp-tooltip-leave-to {
    opacity: 0;
  }

  .rp-tooltip-arrow {
    position: absolute;

    &::before {
      position: absolute;
      content: '';

      border-color: transparent;
      border-style: solid;
    }
  }

  &[data-placement="top"] {
    padding-bottom: $rp-tooltip-arrow-height;

    .rp-tooltip-arrow {
      bottom: 0;

      width: $rp-tooltip-arrow-width;
      height: $rp-tooltip-arrow-height;

      &::before {
        top: 0;

        border-width: $rp-tooltip-arrow-height divide($rp-tooltip-arrow-width, 2) 0;
        border-top-color: $rp-tooltip-background;
      }
    }
  }

  &[data-placement="bottom"] {
    padding-top: $rp-tooltip-arrow-height;

    .rp-tooltip-arrow {
      top: 0;

      width: $rp-tooltip-arrow-width;
      height: $rp-tooltip-arrow-height;

      &::before {
        bottom: 0;

        border-width: 0 divide($rp-tooltip-arrow-width, 2) $rp-tooltip-arrow-height;
        border-bottom-color: $rp-tooltip-background;
      }
    }
  }

  &[data-placement="left"] {
    padding-right: $rp-tooltip-arrow-height;

    .rp-tooltip-arrow {
      right: 0;

      width: $rp-tooltip-arrow-height;
      height: $rp-tooltip-arrow-width;

      &::before {
        left: 0;

        border-width: divide($rp-tooltip-arrow-width, 2) 0 divide($rp-tooltip-arrow-width, 2) $rp-tooltip-arrow-height;
        border-left-color: $rp-tooltip-background;
      }
    }
  }

  &[data-placement="right"] {
    padding-left: $rp-tooltip-arrow-height;

    .rp-tooltip-arrow {
      left: 0;

      width: $rp-tooltip-arrow-height;
      height: $rp-tooltip-arrow-width;

      &::before {
        right: 0;

        border-width: divide($rp-tooltip-arrow-width, 2) $rp-tooltip-arrow-height divide($rp-tooltip-arrow-width, 2) 0;
        border-right-color: $rp-tooltip-background;
      }
    }
  }

  .rp-tooltip-content {
    max-width: $rp-tooltip-max-width;
    padding: $rp-tooltip-padding;

    @include font-settings($rp-tooltip-font);
    text-align: center;

    color: $rp-tooltip-color;
    background: $rp-tooltip-background;

    box-shadow: $rp-tooltip-box-shadow;
    border-radius: $rp-tooltip-border-radius;
  }

  &.large {
    .rp-tooltip-content {
      max-width: $rp-tooltip-max-width-large;

      text-align: left;
    }
  }
}
