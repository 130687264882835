.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: $zindex-fixed;

  display: flex;
  flex-direction: column;

  padding: $nav-link-padding-y 0;

  overflow: auto;
  @include hide-scrollbar();

  background-color: $neutral-surface;
  border-right: $border-width solid $border-color;

  @include media-breakpoint-up(md) {
    display: flex !important;
    width: $sidebar-width !important;
  }

  &.collapsing {
    position: fixed;
    height: auto;
    width: 0;
    @include transition(width .3s ease);
  }

  .nav-text {
    padding: $nav-link-padding-y;
    text-align: center;

    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }

  .nav-link {
    display: flex;
    align-items: center;

    color: $neutral-surface-var-bg;

    .nav-icon {
      padding: $nav-link-padding-y;
      border-radius: 50%;

      .icon {
        display: block;
      }
    }

    &.active {
      color: $primary;
      background-color: $primary-bg;

      .nav-icon {
        color: $primary;
        background-color: $primary-bg;
      }
    }

    @include media-breakpoint-up(md) {
      flex-direction: column;

      &.active {
        background-color: $white;
      }
    }
  }

  .nav-logo {
    justify-content: center;

    width: $sidebar-width * 2;
    padding-bottom: $nav-link-padding-y * 2;

    .logo {
      width: $sidebar-logo-size;
      height: $sidebar-logo-size;
    }

    @include media-breakpoint-up(md) {
      width: auto;
    }
  }
}
