.rp-select-action-list {
  display: flex;
  align-items: center;

  gap: .5rem;
  height: $rp-select-selection-min-height;

  .rp-select-action {
    width: $icon-size-md;
    height: $icon-size-md;
    font-size: $icon-size-md;

    color: $palette-blue-400;
    cursor: pointer;

    &:hover {
      color: $palette-blue-500;
    }
  }

  a {
    display: inline-flex;

    &:hover {
      text-decoration: none;
    }
  }

  &.disabled {
    .rp-select-action {
      color: $palette-black-400;
      cursor: default;

      &:hover {
        color: $palette-black-400;
      }

      &.link {
        color: $palette-blue-400;
        cursor: pointer;

        &:hover {
          color: $palette-blue-500;
        }
      }
    }
  }

  .rp-select-action.clear {
    display: none;
  }

  &.open {
    .rp-select-action.caret {
      display: none;
    }

    .rp-select-action.clear {
      display: inline-block;
    }
  }
}

.rp-select:hover {
  .rp-select-action-list.clearable:not(.disabled) {
    .rp-select-action.caret {
      display: none;
    }

    .rp-select-action.clear {
      display: inline-block;
    }
  }
}
