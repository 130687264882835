.rp-select-message {
  display: flex;
  align-items: center;

  padding: .25rem .75rem;

  @include font-settings($label-medium);

  &.error {
    color: $color-error;
  }
}
