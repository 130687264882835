.rp-offcanvas {
  display: flex;
  flex-direction: column;

  position: fixed;
  top: $header-height;
  right: 0;
  bottom: 0;
  z-index: subtract($zindex-fixed, 1);

  width: $offcanvas-width;
  padding-right: $offcanvas-line-padding;

  background: $neutral-surface;
  border-radius: $offcanvas-border-radius 0 0 $offcanvas-border-radius;
  box-shadow: $elevation-200-left;

  transform: translateX(100%);
  @include transition(transform .3s ease-in-out);

  &::before {
    content: '';

    position: absolute;
    top: 0;
    right: $offcanvas-line-padding;
    bottom: 0;
    z-index: 1;

    border-right: $border-width solid $border-color;
  }

  &:focus-visible {
    outline: 0;
  }

  &.show {
    transform: none;
  }
}

.rp-offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-shrink: 0;

  z-index: 2;

  height: $offcanvas-header-height;
  padding: subtract($offcanvas-padding-x, $offcanvas-padding-y) $offcanvas-padding-x 0;

  .rp-offcanvas-title {
    margin: 0;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include font-settings($title-large);
  }

  .rp-offcanvas-close {
    display: flex;

    position: absolute;
    right: $offcanvas-line-padding;
    transform: translateX(50%);

    padding: $offcanvas-close-padding;

    color: $neutral-surface-var-bg;
    background: $neutral-surface;

    border: $border-width solid $border-color;
    border-radius: 50%;

    .icon {
      font-size: $icon-size-md;
    }
  }
}

.rp-offcanvas-body {
  overflow-y: scroll;
  flex: 1 1 100%;

  z-index: 1;

  padding: 0 $offcanvas-padding-x;
  padding-right: subtract($offcanvas-padding-x, $border-width * 2);
  margin: $offcanvas-padding-y 0;
  margin-right: -$border-width;
}
