// ############################################################################
// Own utility classes
// ############################################################################

.rp-hide-scrollbar {
  @include hide-scrollbar();
}

.rp-hide-caret {
  @include hide-caret();
}

.rp-bg-surface {
  background: $neutral-surface !important;
}

.rp-bg-surface-alt {
  background: $neutral-surface-var-alt !important;
}



// ############################################################################
// Extension of Bootstrap utility classes
// ############################################################################

.text-decoration-underline {
  text-decoration: underline !important;
}
