.topbar {
  border-bottom: $border-width solid $border-color;
  background-color: $header-bg;

  align-items: center;

  @include media-breakpoint-up(md) {
    padding-left: $content-padding-x;
    padding-right: $content-padding-x;
  }

  .btn {
    border-radius: 0;
  }

  .btn-link {
    color: inherit;
  }
}
