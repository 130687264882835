$quick-search-min-width:    25rem;
$quick-search-max-height:   20rem;

.quick-search {
  .dropdown-menu {
    min-width: $quick-search-min-width;
  }

  .quick-search-field {
    display: flex;
    align-items: center;

    position: relative;

    padding: $dropdown-item-padding-y $dropdown-item-padding-x;

    .quick-search-input {
      padding-right: $input-btn-padding-x * 2 + $spinner-width-sm;
    }

    .quick-search-progress {
      position: absolute;
      right: $dropdown-item-padding-x + $input-btn-padding-x;
      z-index: 4;
    }
  }

  .quick-search-results {
    overflow: auto;
    max-height: $quick-search-max-height;

    .dropdown-header {
      padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    }
  }

  .quick-search-result {
    display: flex;
    flex-direction: column;

    padding: $dropdown-item-padding-y * .5 $dropdown-item-padding-x;
    padding-left: $dropdown-item-padding-x * 2;

    small {
      white-space: normal;
    }
  }
}
