$task-calendar-height-min:              30rem;
$task-calendar-height-max:              calc(100vh - $header-height - $footer-height - $content-padding-x - $content-padding-y);

$task-calendar-border-radius:           .5rem;
$task-calendar-border-radius-sm:        .25rem;

$task-calendar-padding:                 .75rem;
$task-calendar-padding-sm:              .5rem;

$task-calendar-sidebar-width:           21rem;
$task-calendar-sidebar-padding:         1rem;

$task-calender-header-height:           4.25rem;
$task-calender-header-padding:          1.25rem;

$task-calender-dates-height:            3.5rem;
$task-calender-day-padding:             .25rem;

$task-calendar-timeless-height-max:     4.5rem;
$task-calendar-salespeople-height-max:  7.5rem;
$task-calender-overdue-title-height:    3.5rem;

$task-calendar-event-indicator-width:   .25rem;
$task-calendar-event-padding:           .25rem;

$task-calendar-time-width:              4.75rem;
$task-calendar-hour-height:             90px;



.rp-task-calendar {
  display: flex;
  overflow: hidden;

  min-height: $task-calendar-height-min;
  max-height: $task-calendar-height-max;

  background: $neutral-surface;
  border: $border-width solid $border-color;
  border-radius: $task-calendar-border-radius;

  .rp-task-calendar-container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .rp-task-calendar-sidebar {
    display: flex;
    flex-direction: column;

    width: $task-calendar-sidebar-width;
    padding: 0 $task-calendar-sidebar-padding;
  }
}

.rp-task-calendar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;

  height: $task-calender-header-height;
  padding: 0 $task-calender-header-padding;
  margin-right: $scrollbar-size * 2;

  background: $neutral-surface-var-alt;
  border-right: $border-width solid $border-color;
  border-bottom: $border-width solid $border-color-alt;

  .rp-task-calendar-header-left {
    display: flex;
    align-items: center;

    gap: $task-calendar-padding-sm;
  }

  .rp-task-calendar-header-right {
    display: flex;
    align-items: center;
  }

  .rp-task-calendar-title {
    margin: 0;

    @include font-settings($title-large);
  }
}

.rp-task-calendar-row {
  display: flex;
  align-items: center;

  @include font-settings($body-medium);

  .rp-task-calendar-time {
    display: flex;
    align-items: center;

    width: $task-calendar-time-width;
    padding: 0 $task-calender-header-padding;

    border-right: $border-width solid $border-color;

    .rp-task-calendar-hour {
      height: $task-calendar-hour-height;

      transform: translateY(divide(-$body-medium-line-height, 2));
    }
  }

  .rp-task-calendar-week {
    display: flex;
    align-items: center;
    flex: 1;

    border-right: $border-width solid $border-color;

    .rp-task-calendar-day {
      display: flex;
      align-items: center;
      flex: 1;

      &:not(:last-child) {
        border-right: $border-width solid $border-color;
      }

      .rp-task-calendar-day-name {
        margin-right: $task-calender-day-padding;
      }

      .rp-task-calendar-day-date {
        &.today {
          padding: divide($task-calender-day-padding, 2) $task-calender-day-padding;
          border-radius: $task-calendar-border-radius-sm;

          color: $neutral-surface;
          background: $color-primary;
        }
      }
    }
  }
}

.rp-task-calendar-event {
  display: flex;
  justify-content: space-between;

  position: relative;
  z-index: 1;

  width: 100%;

  border: $border-width solid $color-primary;
  border-radius: $task-calendar-border-radius-sm;
  border-left-width: $task-calendar-event-indicator-width;
  background: $color-primary-bg;

  padding: divide($task-calendar-event-padding, 2) $task-calendar-event-padding;

  overflow: hidden;
  cursor: pointer;

  &.done {
    border-color: $color-success !important;
  }

  &.cancelled {
    border-color: $color-error !important;
  }

  &.active {
    z-index: 100 !important;
    left: 0 !important;
  }

  &:not(.active) {
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
  }

  &.past {
    &:not(.active) {
      padding: add(divide($task-calendar-event-padding, 2), $border-width) add($task-calendar-event-padding, $border-width);
      padding-left: $task-calendar-event-padding;

      border-width: 0;
      border-left-width: $task-calendar-event-indicator-width;
    }

    &::after {
      content: '';
      pointer-events: none;

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      background: rgba($palette-black-600, .25);
    }
  }
}

.rp-task-calendar-dates {
  margin-right: $scrollbar-size * 2;

  @include font-settings($title-small);

  .rp-task-calendar-time,
  .rp-task-calendar-day {
    justify-content: center;

    height: $task-calender-dates-height;
  }

  .rp-task-calendar-day {
    border-color: transparent !important;
  }
}

.rp-task-calendar-events-container {
  position: relative;
  overflow: hidden scroll;

  &.stop-scroll {
    overflow: hidden;
    margin-right: $scrollbar-size;
  }
}

.rp-task-calendar-events-timeless {
  position: sticky;
  top: 0;
  z-index: 2;

  margin-right: $scrollbar-size;

  background: $neutral-surface-var-alt;

  .rp-task-calendar-day {
    flex-direction: column;

    max-height: $task-calendar-timeless-height-max;

    overflow: auto;
    @include hide-scrollbar();

    border-color: transparent !important;

    &.stop-scroll {
      overflow: hidden;
    }
  }
}

.rp-task-calendar-event-timeless {
  align-items: center;
  flex-shrink: 0;

  gap: $task-calendar-padding-sm;

  .rp-task-calendar-event-timeless-type {
    flex: 2;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @include font-settings($label-small);
  }

  .rp-task-calendar-event-timeless-customer {
    flex: 1;

    text-align: right;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @include font-settings($body-small);
  }
}

.rp-task-calendar-events-regular {
  position: relative;
  z-index: 1;

  margin-right: $scrollbar-size;

  .rp-task-calendar-time {
    flex-direction: column;
  }

  .rp-task-calendar-time,
  .rp-task-calendar-day {
    height: add($task-calendar-hour-height * 24, divide($task-calendar-hour-height, 2));
    padding-top: divide($task-calendar-hour-height, 2);
  }

  .rp-task-calendar-day {
    position: relative;
    overflow: hidden;
  }
}

.rp-task-calendar-event-regular {
  flex-direction: column;

  position: absolute;
  top: 0;

  height: $task-calendar-hour-height;

  .rp-task-calendar-event-regular-type {
    flex-shrink: 0;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @include font-settings($label-medium);
  }

  .rp-task-calendar-event-regular-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: $task-calendar-padding-sm;

    @include font-settings($label-small);

    .rp-task-calendar-event-regular-time {
      flex-shrink: 0;
      white-space: nowrap;
    }

    .rp-task-calendar-event-regular-customer {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.rp-task-calendar-event-new {
  position: absolute;
  top: 0;
  z-index: 100;

  width: 100%;
  height: divide($task-calendar-hour-height, 4);

  border: $border-width solid $color-primary;
  border-left: none;
  border-right: none;
  background: $color-primary-bg;

  padding: divide($task-calendar-event-padding, 2) $task-calendar-event-padding;
}

.rp-task-calendar-salespeople {
  flex-shrink: 0;

  .rp-task-calendar-salespeople-title {
    display: flex;
    align-items: center;

    height: $task-calender-header-height;
    padding: 0 $task-calendar-padding;

    h4 {
      margin: 0;

      @include font-settings($title-medium);
    }
  }

  .rp-task-calendar-salespeople-list {
    display: flex;
    flex-direction: column;

    gap: $task-calendar-padding;

    overflow: auto;
    max-height: $task-calendar-salespeople-height-max;
  }
}

.rp-task-calendar-salesman {
  display: flex;
  align-items: center;
  justify-content: space-between;
  order: 4;

  padding: 0 $task-calendar-padding;

  cursor: pointer;

  .rp-task-calendar-salesman-left {
    display: flex;
    align-items: center;
  }

  .rp-task-calendar-salesman-right {
    display: flex;
    align-items: center;
  }

  .rp-task-calendar-salesman-name {
    padding: 0 $task-calendar-padding;

    @include font-settings($title-small);
  }

  .rp-task-calendar-salesman-toggle {
    font-size: $icon-size-md;

    &.rp-task-calendar-salesman-show {
      display: inline-block;

      color: $neutral-surface-var-bg;
    }

    &.rp-task-calendar-salesman-hide {
      display: none;

      color: $color-primary;
    }
  }

  &[data-current-user] {
    order: 3;
  }

  &.selected {
    order: 2;

    .rp-task-calendar-salesman-show {
      display: none;
    }

    .rp-task-calendar-salesman-hide {
      display: inline-block;
    }

    &[data-current-user] {
      order: 1;
    }
  }
}

.rp-task-calendar-events-overdue {
  display: flex;
  flex-direction: column;

  min-height: 0;

  padding-bottom: $task-calendar-sidebar-padding;

  .rp-task-calendar-events-overdue-title {
    display: flex;
    align-items: center;
    flex-shrink: 0;

    height: $task-calender-overdue-title-height;
    padding: 0 $task-calendar-padding;

    h4 {
      margin: 0;

      @include font-settings($title-medium);
    }
  }

  .rp-task-calendar-events-overdue-list {
    display: flex;
    flex-direction: column;
    flex: 1;

    gap: $task-calendar-padding;
    padding-right: $task-calendar-padding;

    overflow: auto;

    &:empty::before {
      content: attr(data-empty);
      text-align: center;

      color: $neutral-surface-var-bg;

      @include font-settings($label-medium);
    }

    &.stop-scroll {
      overflow: hidden;
      margin-right: $scrollbar-size;
    }
  }
}

.rp-task-calendar-event-overdue {
  display: flex;
  flex-direction: column;

  gap: $task-calendar-padding;
  padding: $task-calendar-padding;

  border: 2px solid $color-error-var;
  border-radius: $task-calendar-border-radius;

  cursor: pointer;

  &.active {
    border-color: $color-error;
  }

  .rp-task-calendar-event-overdue-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: $task-calendar-padding;

    .rp-task-calendar-event-overdue-type {
      @include font-settings($title-small);
    }

    .rp-task-calendar-event-overdue-customer {
      @include font-settings($label-medium);
    }
  }

  .rp-task-calendar-event-overdue-text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    overflow: hidden;

    @include font-settings($body-large);
  }

  .rp-task-calendar-event-overdue-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: $task-calendar-padding;

    @include font-settings($label-medium);

    .rp-task-calendar-event-overdue-salesman {
      display: flex;
      align-items: center;

      gap: $task-calendar-padding-sm;

      .rp-task-calendar-event-overdue-salesman-name {
        color: $neutral-surface-var-bg;
      }
    }

    .rp-task-calendar-event-overdue-deadline {
      color: $color-error;
      white-space: nowrap;
    }
  }
}
