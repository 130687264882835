.btn-container {
  display: flex;
  align-items: center;

  flex-wrap: nowrap;
  white-space: nowrap;

  & > .btn:not(:first-child) {
    margin-left: $spacer * .5;
  }
}
